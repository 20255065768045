import { GetRequest, PostRequest, successParam } from '@/api/base';

export interface GetInitData {
    IsShowPermissionGranted: number;
    IsPwdFreeLogin: number;
    IsPwdFreeLoginNotice: number;
}

export interface GetSignedPolicyInfoByPrivacyPolicy {
    ID: string;
    UUID: string;
    UserName: string;
    ProviderInfo: {
        ID: string;
        UUID: string;
        CompanyName: string;
        CompanyAddress: string;
        Phone: string;
        Email: string;
        TaxId: string;
        WebSite: string;
        PrivacyPolicyVersion: string;
        UserPolicyVersion: string;
        Language: string;
        UpdateTime: string;
        CreateTime: string;
    };
    SignTime: string;
}
// 查看签署的隐私协议
export function getSignedPolicyInfoByPrivacyPolicy(params: object, callback: Function) {
    GetRequest('v3/web/common/privacyPolicy/getSignedPolicyInfo', params, callback);
}

export type GetLatestTemplateListByPrivacyPolicy = {
    ID: string;
    UUID: string;
    Language: string;
    Version: string;
    Type: string;
    CreateTime: string;
    UpdateTime: string;
}[];

// 获取所有最新的隐私模板
export function getLatestTemplateListByPrivacyPolicy(params: object, callback: Function) {
    GetRequest('v3/web/common/privacyPolicy/getLatestTemplateList', params, callback);
}
